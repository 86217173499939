.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.scorebars {
  width: 40px;
  height: auto;
  background: black;
}

.score-bars {
  width: 100%;
  height: 5px;
  /* background: green; */
  margin: 2px 0;
}

.score-label {
  color:#ffffff;
  font-size: 2vw;
  position: absolute;
}

.heartspec {
  display:inline-block;
  width: 100px;
  aspect-ratio: 1;
  border-image: radial-gradient(blue 69%,#0000 70%) 84.5% fill/100%;
  clip-path: polygon(-41% 0,50% 91%, 141% 0);
}

.heart {
  width:200px;
  height:200px;
  background:
   radial-gradient(circle at 50% 83%, red 29%, transparent 30%) -40px -100px/100% 100%,
   radial-gradient(circle at 50% 83%, red 29%, transparent 30%) 40px -100px/100% 100%,
   linear-gradient(to bottom left,#87ceeb 43%,transparent 43%) bottom left/50% 50%,
   linear-gradient(to bottom right,#87ceeb 43%,transparent 43%) bottom right/50% 50%;
  background-repeat:no-repeat;
}

.heartinner {
  background:
   radial-gradient(circle at 50% 83%, red 29%, transparent 30%) -40px -100px/100% 100%,
   radial-gradient(circle at 50% 83%, red 29%, transparent 30%) 40px -100px/100% 100%,
   linear-gradient(to bottom left,red 43%,transparent 43%) bottom left/50% 50%,
   linear-gradient(to bottom right,red 43%,transparent 43%) bottom right/50% 50%;
  background-repeat:no-repeat;
}
